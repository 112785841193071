import React from "react";

import MyTrips from "./MyTrips";
import TripForm from "./TripForm";

export default function Home() {
  return (
    <>
      <MyTrips />
    </>
  );
}
